<template>
  <div id="app"><router-view /></div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    metaInfo() {
      return {
        title: 'Bigbox',
        meta: [
          {
            name: 'description',
            content:
              'Regalá Momentos Inolvidables con Bigbox. ' +
              `Regala experiencias de gastronomía, aventura, bienestar o estadías en ${this.$store.countryName}.`,
            vmid: 'description',
          },
          {
            name: 'keywords',
            content: `${this.$store.defaultKeywords} ${this.$store.staticPagesKeywords}`,
            vmid: 'keywords',
          },
          { property: 'og:site_name', content: 'Bigbox', vmid: 'ogSiteName' },
          {
            property: 'og:image',
            content:
              'http://static.bigbox.com.ar/front/img/logobigbox-192x192.jpg',
            vmid: 'ogImage',
          },
          {
            property: 'og:image:secure_url',
            content:
              'https://static.bigbox.com.ar/front/img/logobigbox-192x192.jpg',
            vmid: 'ogSecureImage',
          },
        ],
      };
    },
  })
  export default class App extends Vue {
    beforeMount() {
      window.addEventListener('resize', () => {
        this.$store.setViewportWidth(window.innerWidth);
      });
    }
  }
</script>

<style scoped>
  @import '~assets/styles/index.scss';

  .app-root {
    position: relative;
  }
</style>
